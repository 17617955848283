/* *{
    outline: 1px solid white;
} */

footer {
  width: 100%;
  background-color: #1e293b;
  height: fit-content;
}

.footer-container {
  width: 60%;
  height: 80px;
  margin: auto;
  background-color: #1e293b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.left-nest {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.middle-nest {
  /* Paragraph/P1 / Regular */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height, or 121% */
  text-align: center;
  color: #f8fafc;
}

.right-nest {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 20px;
}

.footer-legal {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  /* Neutral / Gray 50 */
  color: #f8fafc;
}

.right-nest img {
  opacity: 0.5;
}

.right-nest img:hover {
  opacity: 1.5;
}

/* responsive mobiles and tablets */
@media only screen and (max-width: 900px) {
  .footer-container {
    flex-direction: column;
    gap: 10px;
  }

  .right-nest {
    padding-bottom: 20px;
  }

  .left-nest {
    padding-top: 20px;
  }

  footer {
    position: static;
    width: 100%;
    background-color: #1e293b;
    height: fit-content;
    padding: 20px 0px;
  }
}

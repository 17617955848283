/* * {
  outline: 1px solid yellow;
} */

.banner-container {
  width: 100%;
  height: fit-content;
  margin: auto;
  background: #1e293b;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 48px 0;
}

.name-text {
  margin: 32px 0 24px 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 70px;
  line-height: 100px;
  /* identical to box height, or 117% */
  text-align: center;
  letter-spacing: -0.04em;
  /* Neutral / Gray 50 */
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  background: url(../images/back.png);
  -webkit-background-clip: text;
  background-position: 0 0;
  background-size: cover;
  animation: back 20s linear infinite;
}

@keyframes back {
  100% {
    background-position: 1000px 0;
  }
}

.small-text {
  width: fit-content;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #cbd5e1;
}

.project-arrow {
  opacity: 0.4;
}

.project-arrow:hover {
  opacity: 1;
}

.second-small-text {
  overflow: hidden;
  border-right: 0.1em solid #cbd5e1;
  white-space: nowrap;
  font-size: 20px;
  width: 0;
  animation: typing 8s forwards, blinking 1s infinite;
}

@keyframes blinking {
  50% {
    border-color: transparent;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .banner-container {
    width: 60%;
    height: fit-content;
    margin: auto;
    background: #1e293b;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 84px;
  }
  .second-small-text {
    animation: none;
    width: 100%;
    white-space: normal;
    border-color: transparent;
  }
  .small-text {
    width: 100%;
  }
  .fullstackdev {
    display: none;
  }
}

@media (min-width: 520px) and (max-width: 1024px) {
  .banner-container {
    width: 80%;
    height: fit-content;
    margin: auto;
    background: #1e293b;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .second-small-text {
    animation: none;
    width: 100%;
    white-space: normal;
    border-color: transparent;
  }
}

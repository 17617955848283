/* *{
    outline: 1px solid white;
} */


.box {
    margin: auto;
    position: fixed;
    width: 460px;
    height: 500px;
    background: #1c1c1c;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 100px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.overlay {
    width: 100vw;
    height: 100vh;
    background: #1c1c1c9b;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.box::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 460px;
    height: 500px;
    background: linear-gradient(0deg, transparent, #f2f2f2, #f2f2f2);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
}

.box::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 460px;
    height: 500px;
    background: linear-gradient(0deg, transparent, #f2f2f2, #f2f2f2);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -3s;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.form {
    position: absolute;
    inset: 2px;
    border-radius: 8px;
    background: #242529;
    z-index: 100;
    display: flex;
    flex-direction: column;
    padding: 50px 40px;
}

.form h2 {
    color: #f2f2f2;
    font-family: 'Inter';
    letter-spacing: 0.01em;
    font-weight: 500;
    text-align: center;
}

.inputBox {
    position: relative;
    width: 100%;
    margin-top: 34px;
}

.inputBox input,
textarea {
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    border: none;
    outline: none;
    color: #23242a;
    ;
    font-size: 1em;
    letter-spacing: 0.02em;
    z-index: 10;
}

.inputBox span {
    position: absolute;
    top: 0px;
    left: 0;
    padding: 20px 10px 10px;
    font-size: 1em;
    font-family: "Inter";
    color: rgb(114, 112, 112);
    pointer-events: none;
    letter-spacing: 0.05em;
    transition: 0.5s;
}

.inputBox input:valid~span,
.inputBox input:focus~span,
.inputBox textarea:valid~span,
.inputBox textarea:focus~span {
    color: white;
    transform: translateX(-10px) translateY(-34px);
    font-size: 0.75em;
}

.inputBox i {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #f2f2f2;
    border-radius: 4px;
    overflow: hidden;
    transition: 0.5s;
    pointer-events: none;
}

.inputBox input:valid~i,
.inputBox input:focus~i {
    height: 44px;
}

input[type="submit"] {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #F1F5F9;
    text-decoration: none;
    background-color: #64748B;
    padding: 18px 20px;
    border-radius: 40px;
    transition: transform 0.2s;
    width: 25%;
    margin-top: 36px;
    border: none;
}

input[type="submit"]:hover {
    color: #64748B;
    background: #f2f2f2;
}

.btn-close:hover {
    background-color: #64748B;
}

.btn-close {
    position: absolute;
    top: 14px;
    right: 14px;
    background: transparent;
    border: none;
    border-radius: 40px;
    text-align: center;
}


/* responsice */

@media only screen and (max-width: 700px) {
    .box::after {display: none;}
    .box::before {display: none;}
    .box {
        top: 50px;
        width: 400px;
    }
}

@media only screen and (max-width: 350px) {
    .box::after {display: none;}
    .box::before {display: none;}
    .box {
        top: 50px;
        left: -28px;
        width: 300px;
    }

    input[type='submit'] {
        width: 50%;
    }
}


